@import '../../styles/var';

.mpk-container-transform{

}

@media screen and (max-width: $mpk-media-xs){
  .mpk-container-transform{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    background:rgba(0,0,0,.2);
    padding: $mpk-padding-N;
    > * {
      width:100%;
    }
  }
}