@import '../../styles/var';

.mpk-relative-grid{
  column-gap: $mpk-padding-N;
  margin: $mpk-padding-N auto;
  .figure{
    transition: opacity .4s ease-in-out;
    margin-top: 2px;
    margin-bottom: $mpk-padding-N;
    display: inline-block;
    column-break-inside: avoid;
  }
}