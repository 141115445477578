@import '../../styles/var';

.mpk-corner-ribbon{
  position: absolute;
  right: 0;
  top: 0;
  width: 92px;
  height: 92px;
  overflow: hidden;
  z-index: 1;
    
  .wrapper{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .ribbon{
    font-size: 13px;
    color: #ddd;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform:    rotate(45deg);
    -ms-transform:     rotate(45deg);
    -o-transform:      rotate(45deg);
    position: absolute;
    text-transform: uppercase;
    letter-spacing: .02rem;
    padding: 7px 0;
    left: 0px;
    top: 16px;
    width: 120px;
    background: $mpk-secondary-color;
    font-weight: bold;

    &:before{ 
        left: 0; 
    }
    &:after{ 
        right: 0;
    }
  }
}