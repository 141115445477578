@import '../../styles/var';

.sgn-layout{
  &.column{
    > .sgn-layout-element{
      width: 100%;
    }
  }
  &.row{
    > .sgn-layout-element{
      height: 100%;
    }
  }
}

@media screen and (max-width: $mpk-media-md){
  .sgn-layout{
    &.column{
      &.switch-md{
        flex-direction: row !important;
      }
    }
    &.row{
      &.switch-md{
        flex-direction: column !important;
        >.sgn-layout-element{
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: $mpk-media-sm){
  .sgn-layout{
    &.column{
      &.switch-sm{
        flex-direction: row !important;
        &.reverse{
          flex-direction: row-reverse !important;
        }
      }
    }
    &.row{
      &.switch-sm{
        flex-direction: column !important;
        &.reverse{
          flex-direction: column-reverse !important;
        }
        >.sgn-layout-element{
          width: 100% !important;
        }
      }
    }
  }
}