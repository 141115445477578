@import '../../styles/var';

.mpk-app-logo{
  display: flex;
  align-items: center;
  .picture-mark{
    height:32px;
  }
  .logo-type{
    height:20px;
    margin-left:8px;
  }
  img{
    height: 100%;
  }
}

@media screen and (max-width: $mpk-media-sm){
  .mpk-app-logo{
    .logo-type{
      display: none;
    }
  }
}