@import '../../styles/var';

.mpk-panel{
  position: static;
  &.small-header{
    .header{
      font-size:$mpk-font-size-M;
      border:none !important;
    }
  }
  .header{
    font-size: $mpk-font-size-L;
    font-weight: $mpk-font-weight-B;
    color: $mpk-primary-color;
    position: relative;
    min-height: 60px;
    padding: 0 $mpk-padding-N;
  }
  .md-progress--linear {
    margin: 0;
    position: absolute;
    bottom: -2px;
    left:0;
    height: 3px;
    background: transparent;
  }
}