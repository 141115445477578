
@import '../../styles/var';

.mpk-companies{
  h1{
    font-weight: 600;
    margin-top: 8px;
  }
  .company-center-container{
    margin: 0 auto;
  }
  .lb{
    max-width: 240px;
    top: 8px;
  }
}

@media screen and (max-width: $mpk-media-xs){
  .mpk-companies{
    .company-center-container{
      padding: 0 16px;
    }
    .lb{
      max-width: 100% !important;
      margin: 0 0 24px 0 !important;
      > div{
        border-right: none !important;
      }
    }
    .company-card{
      width: 100%;
      margin: 0 !important;
      .company-card-logo{
        width: 100%;
      }
    }
  }
}