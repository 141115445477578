@import '../../styles/var';

.mpk-file-picker{
  width: 100%;
  #mpk-file-picker-input{
    display: none;
  }
  img{
    width: 100%;
    height:auto;
    margin-bottom: $mpk-margin-N;
  }
  .trigger{
    width: 100%;
    border:thin dashed rgba(0,0,0,.24);
    padding:$mpk-padding-M;
    color:$mpk-font-color-D2;
    cursor: pointer;
    .file-info{
      margin-left:$mpk-padding-N;
    }
  }
}