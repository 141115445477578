.mpk-pagination{
  padding: 8px 0;
  display: flex; 
  align-items: center;
  .pagination-info{
    padding: 0 16px;
  }
  .limit{
    display: flex;
    align-items: center;
    font-size: 12px;
    >div:first-child{
      margin-right: 8px;
    }
  }
  button.md-btn--icon .mdi{
    font-size: 24px
  }
}