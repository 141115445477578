@import '../../styles/var';

.mpk-general-info{
  background-color: $mpk-dark-1;
  padding: $mpk-padding-L;
  color:$mpk-font-color-L2;
  position: relative;
  .link{
    padding-right: $mpk-padding-L;
  }
  .logo{
    position: relative;
    .mpk-logo{
      height:48px;
    }
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      height: 160px;
      background: $mpk-border-color-white;
      margin-left:8px;
      -moz-transform: rotate(12deg);
      -webkit-transform: rotate(12deg);
      transform: rotate(12deg);
    }
  }
}

@media screen and (max-width: $mpk-media-sm){
  .mpk-general-info{
    .logo{
      &:after{
        display: none;
      }
    }
  }
}

@media screen and (max-width: $mpk-media-xs){
  .mpk-general-info{
    .link{
      padding-bottom:$mpk-padding-M;
      border-bottom:thin solid $mpk-border-color-white;
    }
    .mpk-send-email{
      padding-top: $mpk-padding-M;
    }
  }
}