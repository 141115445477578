@import '../../styles/var';

.mpk-image-thumbnail{
  .image-container{
    width: 72px;
    height: 72px;
    position: relative;
    &.selected{
      border: 2px solid $mpk-primary-color;
    }
    img{
      width: 100%;
      height: 100%;
      &.rounded-border{border-radius: 16%}
      &.circle-border{border-radius: 50%}
      object-fit: cover;
      z-index: 1;
      position: relative;
    }
    .md-icon{
      position: absolute;
      right: 4px;
      font-size: 14px;
      top: 4px;
      cursor: pointer;
      color: white;
      text-shadow: 0px 0px 1px rgba(0,0,0,.72);
      z-index: 2;
    }
    .default-img{
      height: 100%;
      width: 100%;
      position: absolute;
      background: #d6d0c9;
      top: 0;
      left: 0;
      z-index: 0;
      .md-icon{
        position: relative;
        cursor: default;
        top: unset;
        right: unset;
        text-shadow: unset;
        font-size: 48px;
      }
    }
  }
}
