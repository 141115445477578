@import '../../styles/var';

.mpk-divider-label{
  margin-top:$mpk-padding-N;
  &:first-child{
    padding-top: 0;
    margin-top:0;
  }
  padding-top: $mpk-padding-N;
  padding-bottom:$mpk-padding-S;
  border-bottom:2px solid $mpk-primary-color;
  font-size: 12px;
  font-weight: 600;
  color: $mpk-primary-color;
  background: white;
  top: 0;
}