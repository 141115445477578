@import '../../styles/var';

.mpk-text-editor{
  margin:$mpk-padding-N 0;
  label{
    margin-bottom:$mpk-padding-S;
    font-size: 13px;
    color:rgba(0,0,0,.48);
    font-weight: 600;
  }
  .error-info{
    margin-top:4px;
    color:$mpk-color-warn;
    font-size: 12px;
  }
  .ql-container.ql-snow{
    background: white;
    z-index: 10;
    border:none;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  .ql-toolbar.ql-snow{
    border: none;
    background: white;
    position: sticky;
    top: 0;
    z-index: 15;
  }
  .ql-editor{
    p{
      margin-bottom: 16px;
    }
  }
}