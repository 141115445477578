@import '../../styles/var';

.mpk-send-email{
  .header{
    margin-bottom: $mpk-padding-N;
  }
  .group{
    display: flex;
    flex-direction: column;
    margin-bottom:$mpk-padding-S;
    label{
      color:$mpk-font-color-L3;
    }
    input, textarea{
      padding:$mpk-padding-S;
      outline:none;
      background: rgba(0,0,0,.08) !important;
      border:thin solid $mpk-border-color-white;
      color:$mpk-font-color-L2;
      font-size: $mpk-font-size-N;
      &:focus{
        border:thin solid $mpk-primary-color;
      }
    }

    textarea{
      resize:none;
      height:120px;
    }
  }
}