@import '../../styles/var';

.mpk-section{
  padding-bottom:$mpk-padding-N;
  .title{
    color:$mpk-primary-color;
    font-weight: $mpk-font-weight-M;
    position: relative;
    display: inline-block;
    padding-bottom:4px;
    font-size:$mpk-font-size-N;
    &:after{
      content:"";
      width: 100%;
      position: absolute;
      height:2px;
      left:0;
      bottom:0;
      background-color: $mpk-primary-color;
    }
  }
  .deck{
    font-size: $mpk-font-size-XXL;
    font-weight: $mpk-font-weight-L;
    color:$mpk-font-color-D3;
    margin-top:$mpk-padding-N;
  }
  .body{
    color:$mpk-font-color-D2;
    margin-top:$mpk-margin-N;
  }
  .footer{
    margin-top:$mpk-padding-N;
  }
  &.dark{
    .title{
      color:$mpk-font-color-L1;
    }
    .body{
      color:$mpk-font-color-L2;
      font-weight: 400;
    }
  }
}