@import '../../styles/var';
.mpk-search-field{
  &.border{
    border-radius:2px;
    background:white;
    border: thin solid rgba(0,0,0,.08);
    .md-divider{
      display: none;
    }
    input{
      margin-top:0;
      padding:0 $mpk-padding-N;
    }
    button{
      height:40px;
      min-width: 48px;
    }
  }
  &.as-panel{
    background: white;
  }
}