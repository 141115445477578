@import '../../styles/var';

.mpk-card-article-thumbnail{
  margin-bottom: $mpk-padding-N;
  .title{
    font-size:$mpk-font-size-XL;
    color:$mpk-primary-color;
    margin-bottom:$mpk-padding-S;
    cursor:pointer;
  }
  .body{
    font-size:$mpk-font-size-N;
    font-weight: $mpk-font-weight-L;
  }
  .info{
    font-size:$mpk-font-size-NS;
    color:$mpk-font-color-D3;
  }
  .image {
    width: 240px;
    min-height:160px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .action{
    margin-top:$mpk-padding-N;
  }
  &.row {
    .content {
      padding:$mpk-padding-N;
    }
  }
  &:last-child{
    margin-bottom:0;
  }
  &.column{
    height:auto;
    .image{
      width:100%;
      height:auto;
      min-height: 0;
      position: relative;
      img{
        height:auto;
        object-fit: contain;
      }
    }
    .content{
      padding:$mpk-padding-N;
    }
  }
}

@media screen and (max-width: $mpk-media-xs){
  .mpk-card-article-thumbnail{
    .image{
      width: 100%;
    }
  }
}