.pr-news-ticker{
  position: relative;
  bottom: 0;
  background: black;
  z-index: 100;
  width: 100vw;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .news-ticker-item{
    color: white;
    display: flex; 
    align-items: center;
    height: 24px;
  }
  &.sp{
    background:#1c2f0c;
    .news-ticker-item-divider{
        background: #689f38;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        text-align: center;
        margin-left: 24px;
    }
  }
}
      