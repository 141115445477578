@import '../../styles/var';

.mpk-footbar{
  background-color: $mpk-dark-logo;
  color:$mpk-font-color-L2;
  padding:$mpk-padding-N;
  button{
    color:$mpk-font-color-L1;
  }
}

@media screen and (max-width: $mpk-media-xs){
  .mpk-footbar{
    padding-top:32px;
    > div:last-child{
      > div:first-child{
        display: none;
      }
    }
  }
}