@import '../../styles/var';

$container-width:240px;

.mpk-sidebar{
  z-index: 10;
  background-color: $mpk-sidebar-color;
  border-right:thin solid $mpk-border-color-dark;
  width: $container-width;
  display:inline-flex;
  color:$mpk-font-color-D1;
  // .mpk-mitra-djp *{
  //   color:$mpk-font-color-D2;
  // }
  @if $mpk-sidebar-theme == 'dark' {
    color:$mpk-font-color-L1;
  }
  &.hide{
    display:none;
  }
}

@media screen and (max-width: $md-tablet-min-width){
  .mpk-sidebar{
    position: absolute;
    left:0;
    height: 100%;
    box-shadow:2px 0px 8px rgba(0,0,0,.25);
    width: 240px;
    z-index: 1;
    display:inline-flex;
    left: 0;
    -webkit-animation: sidebar-show .5s; /* Safari 4+ */
    -moz-animation:    sidebar-show .5s; /* Fx 5+ */
    -o-animation:      sidebar-show .5s; /* Opera 12+ */
    animation:         sidebar-show .5s; /* IE 10+, Fx 29+ */
    &.hide{
      display:inline-flex;
      left: -$container-width;
      -webkit-animation: sidebar-hide .5s; /* Safari 4+ */
      -moz-animation:    sidebar-hide .5s; /* Fx 5+ */
      -o-animation:      sidebar-hide .5s; /* Opera 12+ */
      animation:         sidebar-hide .5s; /* IE 10+, Fx 29+ */
    }
  }
}

@-webkit-keyframes sidebar-show {
  from { left: -$container-width; }
  to { left: 0; }
}
@-moz-keyframes sidebar-show {
  from { left: -$container-width; }
  to { left: 0; }
}
@-o-keyframes sidebar-show {
  from { left: -$container-width; }
  to { left: 0; }
}
@keyframes sidebar-show {
  from { left: -$container-width; }
  to { left: 0; }
}

@-webkit-keyframes sidebar-hide {
  from { left: 0; }
  to { left: -$container-width; }
}
@-moz-keyframes sidebar-hide {
  from { left: 0; }
  to { left: -$container-width; }
}
@-o-keyframes sidebar-hide {
  from { left: 0; }
  to { left: -$container-width; }
}
@keyframes sidebar-hide {
  from { left: 0; }
  to { left: -$container-width; }
}