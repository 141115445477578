@import '../../styles/var';

.mpk-commandbar{
  background:$mpk-grey-1;
  height:48px;
  padding: 0 $mpk-padding-N;
  width: 100%;
  color:$mpk-font-color-D2;
  flex: none;
  .actions{
    padding: 0 $mpk-padding-S;
  }
  .right-corner{
    padding-left :$mpk-padding-N;
    border-left:thin solid $mpk-border-color-dark;
  }
  .left-corner{
    padding-right :$mpk-padding-N;
    margin-right:$mpk-padding-N;
    border-right:thin solid $mpk-border-color-dark;
  }
}

.mpk-breadcrumb-item{
  font-weight: 300;
  display: contents;
  &:after{
    content: '|';
    padding: 0 8px;
  }
}