.mpk-invoice{
  .sub{
    td{
      background: #f2f2f2;
    }
  }
  .total{
    td{
      border-top: thin solid #aaa;
      background: #ddd
    }
  }
}