@import '../../styles/var';

.mpk-error-page{
  margin:0 auto;
  position: relative;
  .md-paper{
    width:800px;
    margin:auto;
    padding:$mpk-padding-L;
  }
  .status{
    font-size:24px;
    color:$mpk-color-warn;
    font-weight: bold;
    margin-right:4px;
  }
  .status-text{
    font-size: 24px;
  }
  .illustration{
    .sign{
      position: relative;
    }
    .sign h1{
      position: absolute;
      width: 100%;
      text-align: center;
      top: 0;
      left: 0;
      margin-top: 48px;
      font-weight: bold;
      font-size: 92px;
      color: #222;
    }
    .sign .board{
      width: 140px;
      height: 140px;
      border-radius: 16px;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f8fc00+0,f4db18+59,fccb19+61,fccb19+100 */
      background: #f8fc00; /* Old browsers */
      background: -moz-linear-gradient(-72deg, #f8fc00 0%, #f4db18 59%, #fccb19 61%, #fccb19 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-72deg, #f8fc00 0%,#f4db18 59%,#fccb19 61%,#fccb19 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(160deg, #f8fc00 0%,#f4db18 59%,#fccb19 61%,#fccb19 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8fc00', endColorstr='#fccb19',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      transform: rotate(45deg);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
    .sign .board:after{
      content:'';
      height: 128px;
      width: 128px;
      border-radius: 16px;
      border: 6px solid rgba(0,0,0,.92);
    }
  }

  .body-content{
    border-left: thin solid rgba(0,0,0,.16);
    margin-left: 48px;
    padding-left: 24px; 
  }

  .mpk-body * {
    font-size: inherit !important;
    word-break: keep-all;
  }
}

@media screen and(max-width: 800px){
  .mpk-error-page{
    padding:$mpk-padding-N;
    .md-paper{
      width: 100%;
    }
  }
}

@media screen and (max-width: $mpk-media-xs){
  .mpk-error-page{
    height: auto !important;
  }
}

@media screen and (max-width: $mpk-media-sm){
    .mpk-error-page{
      .paper{
        padding:0;
        background: transparent;
        box-shadow: none;
      }
      .content{
        flex-direction: column;
      }
      .illustration{
        padding: 0;
        margin-top: 24px;
      }
      .body-content{
        border-top: thin solid rgba(0,0,0,.2);
        border-left: none;
        padding-left: 0;
        padding-top: 24px;
        margin-top: 64px;
        margin-left: 0;
        align-items: center;
      }
    }
  }